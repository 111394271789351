import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class SettingService{
	apiUrl = baseUrl

	async loadSetting () {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/fetch-setting`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateSetting (postData) {
		const apiToken = storageService.getItem().token
		return await axios.post(`${baseUrl}/update-setting`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateEmail (postData) {
		const apiToken = storageService.getItem().token
		return await axios.post(`${baseUrl}/update-email`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updatePassword (postData) {
		const apiToken = storageService.getItem().token
		return await axios.post(`${baseUrl}/update-password`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateVideo (postData, videoId) {
		const apiToken = storageService.getItem().token
		return await axios.put(`${baseUrl}/submit-video/${videoId}`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}
const settingService = new SettingService()
export { settingService }