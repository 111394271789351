<template>
	<div class="container mt-4">
		<h2 class="label-header">Profile</h2>
		<div class="card mb-4">
			<div class="card-header">
				Edit Email
			</div>
			<div class="card-body">
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="isLoading" style="width: 100%; heigth: 100px;">
					<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
				</div>
				<form @submit.prevent="updateEmail" novalidate v-if="!isLoading">
					<div class="row g-3">
						<div class="form-group mb1">
							<label for="">Email</label>
							<input type="text" v-model="vv.emailAddress.$model" placeholder="Enter email" class="form-control">
							<span class="error">{{ vv?.emailAddress?.$errors[0]?.$message }}</span>
						</div>
						<div>
							<button class="btn btn-primary btn-block" :disabled="vv.emailAddress.$invalid">Update Email</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="isLoading" style="width: 100%; heigth: 100px;">
					<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
				</div>
				<form @submit.prevent="updatePassword" novalidate v-if="!isLoading">
					<div class="row g-3">
						<div class="form-group mb1">
							<label for="">Current Password</label>
							<input type="password" v-model="vv.current_password.$model" placeholder="Enter current password" class="form-control">
							<span class="error">{{ vv?.current_password?.$errors[0]?.$message }}</span>
						</div>
						<div class="form-group mb1">
							<label for="">New Password</label>
							<input type="password" v-model="vv.new_password.$model" placeholder="Enter new password" class="form-control">
							<span class="error">{{ vv?.new_password?.$errors[0]?.$message }}</span>
						</div>
						<div class="form-group mb1">
							<label for="">Confirm New Password</label>
							<input type="password" v-model="vv.confirm_password.$model" placeholder="Re-enter new password" class="form-control">
							<span class="error">{{ vv?.confirm_password?.$errors[0]?.$message }}</span>
						</div>
						<div>
							<button class="btn btn-primary btn-block" :disabled="vv.current_password.$invalid && vv.new_password.$invalid && vv.confirm_password.$invalid">Update Password</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="isLoading" style="width: 100%; heigth: 100px;">
					<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
				</div>
				<form @submit.prevent="updateSetting" novalidate v-if="!isLoading">
					<div class="row g-3">
						<div class="form-group mb1">
							<label for="">Paystack Public Key</label>
							<input type="text" v-model="paystackKeys.public" placeholder="Enter public key" class="form-control">
						</div>
						<div class="form-group mb1">
							<label for="">Paystack Secret Key</label>
							<input type="text" v-model="paystackKeys.secret" placeholder="Enter secret key" class="form-control">
						</div>
						<div>
							<button class="btn btn-primary btn-block">Update Password</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import { settingService } from '@/services/setting.service'
export default {
	name: 'Profile',
	setup () {
		const fform = reactive({
			emailAddress: '',
			content: '',
			news_type: null,
			current_password: '',
			new_password: '',
			confirm_password: ''
		})
		const rules = computed(() => (
		{
			emailAddress: { required, email },
			content: { required },
			news_type: { required },
			current_password: { required },
			new_password: { required },
			confirm_password: { required, sameAs: sameAs(fform.new_password) }
		}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			emailAddress: '',
			isLoading: true,
			paystackKeys: {
				public: '',
				secret: ''
			}
		};
	},
	mounted() {
		this.loadPage()
	},
	methods: {
		loadPage () {
			settingService.loadSetting()
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.fform.emailAddress = response.data.setting.email
						this.paystackKeys.public = response.data.setting.paystack_pk
						this.paystackKeys.secret = response.data.setting.paystack_sk
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
				})
		},
		updateEmail () {
			const postData = {
				email: this.fform.emailAddress
			}
			this.isLoading = true
			settingService.updateEmail(postData)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.$swal({
							icon: "success",
							text: "Email updated"
						})
					}else{
						this.$swal({
							icon: "error",
							text: "Error updating email"
						})
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
					this.$swal({
						icon: "error",
						text: "Error updating email"
					})
				})
		},
		updateSetting () {
			this.isLoading = true
			const postData = {
				paystack_pk: this.paystackKeys.public,
				paystack_sk: this.paystackKeys.secret
			}
			settingService.updateSetting({setting: JSON.stringify(postData)})
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.$swal({
							icon: "success",
							text: "Setting updated"
						})
					}else{
						this.$swal({
							icon: "error",
							text: "Error updating setting"
						})
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
					this.$swal({
						icon: "error",
						text: "Error updating setting"
					})
				})
		},
		updatePassword () {
			const postData = {
				old_password: this.fform.current_password,
				new_password: this.fform.new_password
			}
			this.isLoading = true
			settingService.updatePassword(postData)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.fform.current_password = ''
						this.fform.new_password = ''
						this.fform.confirm_password = ''
						this.$swal({
							icon: "success",
							text: "Password updated"
						})
					}else if(parseInt(response.data.message_code) === 510) {
						this.$swal({
							icon: "error",
							text: "Current pasword do not match your password"
						})
					}else{
						this.$swal({
							icon: "error",
							text: "Error updating password"
						})
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
					this.$swal({
						icon: "error",
						text: "Error updating password"
					})
				})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
</style>
